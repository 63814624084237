import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import styled from 'styled-components';
import DeliveryOrderPanel from "../yandex/panels/DeliveryOrderPanel";

export default function IndexApp(props) {

    return (
        <Wrapper>
            <DeliveryOrderPanel orderId={gup('id')}/>
        </Wrapper>
    );
}

const Wrapper = styled.div`
    box-sizing: border-box;
    width: 100vw;
    height: 100vh;
`;

function gup(name, url) {
    if (!url) url = window.location.href;
    name = name.replace(/[\[]/, "\\\[").replace(/[\]]/, "\\\]");
    let regexS = "[\\?&]" + name + "=([^&#]*)";
    let regex = new RegExp(regexS);
    let results = regex.exec(url);
    return results == null ? null : results[1];
}
