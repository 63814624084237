import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import axios from 'axios'

import Spin from 'arui-feather/spin'

export const DARK_RED = '#cc1626';
export const DARK_BLUE = '#4264fb';

export const RED = '#bf4949';
export const GREEN = '#7cc583';
export const BLUE = 'rgb(124, 162, 196)';

const getColorByProps = props => {
    let {status, resolution} = props;
    if (resolution == 'failed') {
        return RED;
    }
    if (resolution == 'success') {
        return GREEN;
    }
    return BLUE;
}

export default function ViewOrderDetailsTool(props) {
    let {
        ru_status, status, pricing_info, resolution, from_name, to_name,
        timestamp, performer_phone_data,
        from_phone, to_phone, performer, customer_price,
        id,
        orderState = undefined,

        approving = false,
        canceling = false,

        canceledByUser = false,

        onApprove = () => {

        },
        onCancel = () => {

        },
        onCancelDialogOpen = () => {

        }
    } = props;

    let color = getColorByProps(props);

    const [cancelModalVisible, setCancelModalVisible] = useState(false);

    // console.log('ViewOrderDetailsTool: render: id = ', id);

    return (
        <Wrapper>

            {(cancelModalVisible == false || orderState == undefined || canceledByUser == true) ? null :
                <CancelOverlay>
                    <CancelDialogBox>
                        <CancelCloseImg src={require('../images/cancel_cross.svg')} onClick={() => {
                            setCancelModalVisible(false);
                        }}/>
                        <CancelDialogHeader>
                            Отмена доставки
                        </CancelDialogHeader>
                        <CancelContent>
                            {orderState.isFreeCancel == true ?
                                'Доступна бесплатная отмена заказа' : 'Данный заказ можно отменить платно. Сумма возврата будет расчитана после отмены.'
                            }
                            <div style={{
                                marginTop: 20, display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center'
                            }}>
                                <Spin visible={canceling}/>
                                {canceling == true ? null :
                                    <AcceptButton onClick={() => {
                                        onCancel();
                                        setCancelModalVisible(false);
                                    }}>
                                        ОТМЕНИТЬ ВЫЗОВ КУРЬЕРА
                                    </AcceptButton>
                                }
                            </div>
                        </CancelContent>
                    </CancelDialogBox>
                </CancelOverlay>
            }


            <StatusSection color={color}>
                {ru_status}
            </StatusSection>

            {orderState == undefined || canceling || approving ? null :
                <ControlsSection>
                    {orderState.canApprove == false ? null :
                        <InnerControlsSection>
                            <div>
                                {`Подтвердите заявку и такси приедет через  ${orderState.eta} минут`}
                            </div>
                            <div style={{marginTop: 10, display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                                {approving == true ? null :
                                    <AcceptButton onClick={() => {
                                        onApprove();
                                    }}>
                                        ПОДТВЕРДИТЬ
                                    </AcceptButton>
                                }
                                {canceling == true ? null :
                                    <CancelSpan style={{marginLeft: 15}} onClick={() => {
                                        setCancelModalVisible(true);
                                        onCancelDialogOpen();
                                    }}>
                                        отменить
                                    </CancelSpan>
                                }
                            </div>
                        </InnerControlsSection>
                    }
                </ControlsSection>
            }

            <div style={{padding: 20}}>
                <Spin visible={canceling || approving}/>
            </div>

            {performer == undefined ? null :
                <PerformerSection>
                    <CarImgPlaceholder>
                        <CarImg src={require('../images/car2.svg')}/>
                    </CarImgPlaceholder>
                    <CarName>
                        {performer.car_model}
                    </CarName>
                    <CarNumber>
                        {performer.car_number}
                    </CarNumber>
                </PerformerSection>
            }

            <FromToSection>
                <FromToBadgePlaceholder>
                    <BadgeCircle color={DARK_RED}></BadgeCircle>
                </FromToBadgePlaceholder>
                <FromToLabel>
                    {from_name}
                </FromToLabel>
            </FromToSection>

            <FromToSection>
                <FromToBadgePlaceholder>
                    <BadgeCircle color={DARK_BLUE}></BadgeCircle>
                </FromToBadgePlaceholder>
                <FromToLabel>
                    {to_name}
                </FromToLabel>
            </FromToSection>

            {performer_phone_data == undefined ? null :
                <Section>
                    <Label>
                        Телефон курьера
                    </Label>
                    <Value>
                        {`${performer_phone_data.phone}, доб. ${performer_phone_data.ext}`}
                    </Value>
                </Section>
            }

            {performer == undefined ? null :
                <Section>
                    <Label>
                        Имя курьера
                    </Label>
                    <Value>
                        {performer.courier_name}
                    </Value>
                </Section>
            }

            {/*{JSON.stringify(orderState)}*/}

            {/*{customer_price == undefined ? null :*/}
            {/*<Section>*/}
            {/*<Label>*/}
            {/*Стоимость*/}
            {/*</Label>*/}
            {/*<Value>*/}
            {/*{`${customer_price} руб.`}*/}
            {/*</Value>*/}
            {/*</Section>*/}
            {/*}*/}


            <Section>
                <Label>
                    Дата создания доставки
                </Label>
                <Value>
                    {moment(timestamp).format('DD.MM.YYYY HH:mm')}
                </Value>
            </Section>

        </Wrapper>
    );
}

const CancelOverlay = styled.div`
    background: rgba(0, 0, 0, 0.3);
    position: fixed;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    z-index: 2;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

const CancelCloseImg = styled.img`
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    height: 18px;
    :hover{
      opacity: 0.8;
    }
`;

const CancelDialogBox = styled.div`
    background: white;
    padding: 20px;
    border-radius: 6px;
    width: 420px;
    box-sizing: border-box;
    min-height: 160px;
    position: relative;
    @media(max-width: 520px){
      width: 100%;
    }
`;

const CancelDialogHeader = styled.div`
    text-align: center;
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 20px;
`;

const CancelContent = styled.div`
    text-align: center;
`;

const AcceptButton = styled.div`
    color: rgb(255, 255, 255);
    text-transform: uppercase;
    text-shadow: rgba(0, 0, 0, 0.3) 0px -1px 1px;
    border-right: none;
    border-left: none;
    border-image: initial;
    border-bottom: 1px solid rgb(172, 172, 172);
    border-top: 1px solid rgb(247, 163, 48);
    cursor: pointer;
    outline: 0px;
    background-color: rgb(247, 163, 48);
    box-shadow: rgb(223, 223, 223) 1px 0px 1px;
    font: 400 14px system-ui;
    border-radius: 3px;
    padding: 6px 24px;
`;

const CancelSpan = styled.div`
    color: red;
    cursor: pointer;
    opacity: 0.7;
    :hover{
      opacity: 1;
    }
`;

const Wrapper = styled.div`
    
`;

const ControlsSection = styled.div`
    
`;

const InnerControlsSection = styled.div`
    padding: 20px;
`;

const MainSubLabel = styled.div`
    
`;

const CarImg = styled.img`
    width: 24px;
    opacity: 0.7;
`;

const CarImgPlaceholder = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 40px;
`;

const CarName = styled.div`
    font-weight: bold;
`;

const CarNumber = styled.div`
    margin-left: 10px;
    padding: 5px;
    background: #d3d3d3ba;
    border-radius: 6px;
    padding-left: 10px;
    padding-right: 10px;
`;

const PerformerSection = styled.div`
    height: 50px;
    padding: 20px;
    box-sizing: border-box;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
`;

const StatusSection = styled.div`
    font-size: 14px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    min-height: 50px;
    height: 50px;
    box-sizing: border-box;
    overflow: hidden;
    padding-left: 20px;
    padding-right: 20px;
    text-align: center;
    border-bottom: 1px solid lightgrey;
    color: white;
    background: ${props => (props.color)};
    font-weight: bold;
`;

const FromToSection = styled.div`
    height: 50px;
    max-height: 50px;
    width: 100%;
    max-width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    box-sizing: border-box;
    overflow: hidden;
    border-bottom: 1px solid #d3d3d359;
`;

const FromToBadgePlaceholder = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding-left: 20px;
`;

const FromToLabel = styled.div`
    font-size: 18px;
    padding-left: 20px;
`;

const BadgeCircle = styled.div`
    border: 6px solid ${props => props.color};
    border-radius: 100px;
    height: 18px;
    width: 18px;
    box-sizing: border-box;
`;

const Label = styled.div`
    height: 30px;
    line-height: 30px;
    background: #d3d3d3ba;
    color: grey;
    font-weight: bold;
    font-size: 12px;
    padding-left: 20px;
`;

const Section = styled.div`
    border-bottom: 1px solid #d3d3d359;
`;

const Value = styled.div`
    padding: 20px;
`;
