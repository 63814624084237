import React from 'react';
import IndexApp from "./components/apps/IndexApp";


function App() {
    return (
        <IndexApp />
    );
}

export default App;
