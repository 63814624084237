import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Spin from 'arui-feather/spin'
import axios from 'axios'

import {RED, GREEN, BLUE, DARK_RED, DARK_BLUE} from "../tools/ViewOrderDetailsTool";

import './style.css'

import mapboxgl from 'mapbox-gl';
import ViewOrderDetailsTool from "../tools/ViewOrderDetailsTool";

const MAPBOX_TOKEN = 'pk.eyJ1IjoicGF5a2VlcGVyIiwiYSI6ImNrZW41ZDE1ZTF1d3YyeW5waWtqZnI4cTQifQ.kDNVelglLMW6uFVz8sMO0A';
const ENDPOINT = `https://api.taxi.sabir.pro`;

mapboxgl.accessToken = MAPBOX_TOKEN;

async function loadOrder(orderId) {
    let url = `${ENDPOINT}/orders/${orderId}/short`;
    let pld = (await axios.get(url)).data.result;
    return pld;
}

async function cancelOrder(orderId) {
    let url = `${ENDPOINT}/orders/${orderId}/cancel`;
    let pld = (await axios.post(url)).data.result;
    return pld;
}

async function acceptOrder(orderId) {
    let url = `${ENDPOINT}/orders/${orderId}/accept`;
    let pld = (await axios.post(url)).data.result;
    return pld;
}

async function loadOrderState(orderId) {
    let url = `${ENDPOINT}/orders/${orderId}/state`;
    let pld = (await axios.get(url)).data.result;
    return pld;
}

async function loadOrderPoints(orderId) {
    let url = `${ENDPOINT}/taxi/order/${orderId}/points`;
    let pld = (await axios.get(url)).data.result;
    return pld;
}


function getBounds(from, to, current) {
    let latArr = [from.lat, to.lat];
    let lonArr = [from.lon, to.lon];
    if (current != undefined) {
        latArr.push(current.lat);
        lonArr.push(current.lon);
    }
    let minLat = Math.min(...latArr);
    let maxLat = Math.max(...latArr);
    let minLon = Math.min(...lonArr);
    let maxLon = Math.max(...lonArr);

    let dLat = maxLat - minLat;
    let dLon = maxLon - minLon;

    let latPadding = dLat * 0.2;
    let lonPadding = dLon * 0.2;

    return [
        [minLon - lonPadding, minLat - latPadding],
        [maxLon + lonPadding, maxLat + latPadding],
    ]
}

const refreshMapWithNewData = (order, mapRef, fromMarkerRef, toMarkerRef, carMarkerRef) => {
    if (order == undefined) {
        return;
    }
    let {fromPosition, toPosition, latest_lat, latest_lon} = order;

    fromMarkerRef.current = new mapboxgl.Marker({color: DARK_RED})
        .setLngLat([fromPosition.lon, fromPosition.lat])
        .addTo(mapRef.current);
    toMarkerRef.current = new mapboxgl.Marker({color: DARK_BLUE})
        .setLngLat([toPosition.lon, toPosition.lat])
        .addTo(mapRef.current);

    if (latest_lat != undefined && latest_lon != undefined) {
        carMarkerRef.current = new mapboxgl.Marker()
            .setLngLat([latest_lon, latest_lat])
            .addTo(mapRef.current);
    }

    mapRef.current.fitBounds(getBounds(fromPosition, toPosition, (latest_lat == undefined) ? undefined : {
        lat: latest_lat,
        lon: latest_lon
    }));
}

const addLineToMap = (mapRef, coords = []) => {
    if (coords.length == 0) {
        return;
    }
    mapRef.current.addSource('route', {
        'type': 'geojson',
        'data': {
            'type': 'Feature',
            'properties': {},
            'geometry': {
                'type': 'LineString',
                'coordinates': coords
            }
        }
    });
    mapRef.current.addLayer({
        'id': 'route',
        'type': 'line',
        'source': 'route',
        'layout': {
            'line-join': 'round',
            'line-cap': 'round'
        },
        'paint': {
            'line-color': '#888',
            'line-width': 8
        }
    });
}

export default function DeliveryOrderPanel(props) {
    const {orderId} = props;
    const map = useRef();
    const mapContainer = useRef();

    const fromMarker = useRef();
    const toMarker = useRef();
    const carMarker = useRef();

    const [orderState, setOrderState] = useState(undefined);
    const [order, setOrder] = useState(undefined);
    const [loading, setLoading] = useState(true);
    const [canceling, setCanceling] = useState(false);
    const [approving, setApproving] = useState(false);


    useEffect(() => {
        mapboxgl.accessToken = MAPBOX_TOKEN;
        map.current = new mapboxgl.Map({
            container: mapContainer.current,
            style: 'mapbox://styles/mapbox/streets-v11',
            center: [37.6175, 55.752]
        });
        loadOrder(orderId).then(ord => {
            if (ord == undefined) {
                return;
            }
            setOrder(ord);
            refreshMapWithNewData(ord, map, fromMarker, toMarker, carMarker);

            setTimeout(() => {
                setLoading(false);
                setTimeout(() => {
                    if (ord.resolution != undefined) {
                        loadOrderPoints(orderId).then(pts => {
                            let coords = pts.sort((a, b) => (+a.t - +b.t)).map(a => ([a.lon, a.lat]));
                            try {
                                addLineToMap(map, coords)
                            } catch (e) {

                            }
                        });
                    }
                }, 5000);
            }, 6500);

            loadOrderState(orderId).then(st => {
                setOrderState(st);
            });

        });
    }, [orderId]);

    useEffect(() => {
        const interval = setInterval(() => {
            loadOrder(orderId).then(ord => {
                if (ord == undefined) {
                    return;
                }
                setOrder(ord);
                let {latest_lat, latest_lon} = ord;
                try {
                    if (carMarker.current != undefined) {
                        carMarker.current.setLngLat([latest_lon, latest_lat]);
                    } else {
                        carMarker.current = new mapboxgl.Marker({color: '#FFCC00'})
                            .setLngLat([latest_lon, latest_lat])
                            .addTo(map.current);
                    }
                } catch (exc) {

                }
                loadOrderState(orderId).then(st => {
                    setOrderState(st);
                });

            });
        }, 5000);
        return () => clearInterval(interval);
    }, []);

    let overlayVisible = (order == undefined || loading == true);

    return (
        <Wrapper>

            <Left>
                <MapPlaceholder ref={mapContainer} id={'main_map'}>

                </MapPlaceholder>
            </Left>

            <Right onClick={() => {
                // if (carMarker.current != undefined) {
                //     // carMarker.current.setLngLat([37.6175, 55.752]);
                //     carMarker.current.setLngLat([37.6175 + Math.random() * 0.01, 55.752 + Math.random() * 0.01])
                // } else {
                //     carMarker.current = new mapboxgl.Marker({color: '#FFCC00'})
                //         .setLngLat([37.6175, 55.752])
                //         .addTo(map.current);
                // }
            }}>
                {order == undefined ? null :
                    <ViewOrderDetailsTool {...order}
                                          canceling={canceling}
                                          approving={approving}
                                          orderState={orderState}
                                          onApprove={async () => {
                                              setApproving(true);
                                              await acceptOrder(orderId);
                                              await delay(7 * 1000);
                                              setApproving(false);
                                          }}
                                          onCancel={async () => {
                                              setCanceling(true);
                                              await cancelOrder(orderId);
                                              await delay(5 * 1000);
                                              setCanceling(false);
                                              window.location.reload();
                                          }}
                                          onCancelDialogOpen={async () => {
                                              let st = await loadOrderState(orderId);
                                              setOrderState(st);
                                          }}
                    />

                }
            </Right>

            {overlayVisible == false ? null :
                <Overlay>
                    <OverlayInner>
                        <LoaderImage src={require('../images/box.svg')} className={'animated rotate'}/>
                        <PkNiceLogo src={require('../images/pk_nice_logo.svg')}/>
                    </OverlayInner>
                </Overlay>
            }

        </Wrapper>
    );
}

const rightWidth = 320;

const MapPlaceholder = styled.div`
    width: 100%;
    height: 100%;
    box-sizing: border-box;
`;

const Overlay = styled.div`
    position: fixed;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    z-index: 10;
    background: rgba(179,179,181,0.8);
`;

const LoaderImage = styled.img`
    height: 120px;
    opacity: 0.7;
`;

const OverlayInner = styled.div`
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

const PkNiceLogo = styled.img`
    height: 50px;
    margin-top: 30px;
`;

const Wrapper = styled.div`
    box-sizing: border-box;
    width: 100%;    
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: stretch;
    position: relative;
`;

const Left = styled.div`
    flex: 1;
    background: lightgrey;
    box-sizing: border-box;
`;

const Right = styled.div`
      width: ${rightWidth}px;
      height: 100%;
      max-height: 100%;
      overflow-y: auto;
      box-sizing: border-box;
      border-left: 1px solid lightgrey;
`;

function delay(time) {
    return new Promise(function (resolve) {
        setTimeout(resolve, time)
    });
}
